@media(max-width:768px) {
  /* just for the profile card at phone-sized resolutions, can be replaced */
  .card.w-50 {
    width: 100%!important;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin: 2rem 0;
}

.videoWrapper>iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: none;
}

body {
  background: url('./images/bg-tile.png');
  color: white;
  font-size: 16px;
  font-family: mr-eaves-xl-modern, sans-serif;
}

.leaderboard {
  background-color: white;
}
.leaderboard th,
.leaderboard td {
  white-space: nowrap;
  padding: 1ex;
}
.leaderboard tbody th {
  text-align: left;
}
.leaderboard tbody tr:nth-child(odd) {
  background-color: #eee;
}
.leaderboard td {
  text-align: right;
}